<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import * as moment from 'moment'
import Swal from "sweetalert2";
import PopUpAddProduct from './add-product';

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Edit Appointment",
    meta: [{ label: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, PopUpAddProduct },
  data() {
    return {
      title: "Form Edit Appointment",
      items: [
        {
          text: "Home",
          href: "/home",
        },
        {
          text: "Appointment",
          href: "/appointment",
        },
        {
          text: "Edit Appointment",
          active: true,
        },
      ],
      form: {
        user: null, 
        location:null,
        room:null,
        date: null,
        time: null,
        status:null,
        paymentStatus:null,
        paymentMethod: null,
        treatments: [{
          treatment:null,
          treatmentNotSelected: false,
          employee:null,
          employeeNotSelected: false,
          employees: []
        }],
        discount: 0,
        package: null,
        notes: null,
      },
      isError: false,
      errorMessage: null,
      optionsStatus: [
        "scheduled",
        "confirmed",
        "in progress",
        "canceled",
        "completed", 
      ],
      isStatusNotSelected:false, 
      optionsPayment: [
        "paid",
        "unpaid", 
      ],
      isPaymentStatusNotSelected:false, 
      optionsPaymentMethod: ['cash', 'debit', 'credit card', 'transfer', 'OVO', 'go pay', 'shopee pay', 'blibli in store', 'kredivo', 'voucher', 'package', 'yobo'],
      isPaymentMethodNotSelected: false,
      disabledPaymentMethod: false,
      users: [],
      isLoadingUser: false,
      isUserNotSelected: false,
      locations: [],
      isLoadinglocations: false,
      islocationsNotSelected: false,
      rooms: [],
      isRoomNotSelected: false,
      treatments: [],
      timeSlot: [],
      isTimeNotSelected: false,
      isDateNotSelected: false,
      checkout: false,
      // packages: [],
      products: [],
      showPopUpAddProduct: false,
      voucherCode: null,
      voucher: null,
      isVoucherInValid: false,
      availablePackages: [],
      selectedPackageId: null,
    };
  },
  computed: {
    payload() {
      let _ = this;
      let usedUserPackageTreatmentIds = [];

      return {
        treatments: _.form.treatments.map(function(x, i) {
          let userPackageTreatmentId = null;

          if (x.package) {
            userPackageTreatmentId = _.generatePackages(usedUserPackageTreatmentIds, x.package._id, x.treatment?.treatmentId);
            if (userPackageTreatmentId) usedUserPackageTreatmentIds.push(userPackageTreatmentId);
            else _.form.treatments[i].package = null;
          }

          return {
            treatmentId: x.treatment?.treatmentId,
            employeeId: x.employee?.employeeId,
            packageId: x.package?._id,
            userPackageTreatmentId
          }
        }),
      }
    },
    packages() {
      const p = [];
      for (let i = 0; i < this.payload.treatments.length; i++) {
        const treatment = this.payload.treatments[i];
        const indexUserPackageTreatment = this.availablePackages
          .map(x => x._id)
          .indexOf(treatment.userPackageTreatmentId);
        if (indexUserPackageTreatment != -1)
          p.push(this.availablePackages[indexUserPackageTreatment]);
      }
      return p;
    },
    selectedPackageTreatment() {
      const treatmentInPackage = []
      this.packages.forEach(userPackage => {
        treatmentInPackage.push(userPackage.treatment)
      })
      return treatmentInPackage
    },
    totalPackage() {
      // if (this.selectedPackageTreatment.length) {
      //   let total = 0;
      //   for (let index = 0; index < this.packages.length; index++) {
      //     const userPackage = this.packages[index].userPackage;
      //     let userPackageQuantity = 0;
      //     for (let zindex = 0; zindex < userPackage.history.length; zindex++) {
      //       const history = userPackage.history[zindex];
      //       userPackageQuantity += history.quantity;
      //     }
      //     total += Math.floor(userPackage.total / userPackageQuantity);
      //   }
      //   return total;
      // }
      // return 0

      if (this.selectedPackageTreatment.length) {
        let total = 0
        for (let index = 0; index < this.form.treatments.length; index++) { // looping treatment
          const treatmentInForm = this.form.treatments[index];
          for (let xindex = 0; xindex < this.packages.length; xindex++) { // looping paket
            const paket = this.packages[xindex];
            if (treatmentInForm.treatment.treatmentId == paket.treatment._id) {
              const userPackage = this.packages[xindex].userPackage;
              let userPackageQuantity = 0;
              for (let zindex = 0; zindex < userPackage.history.length; zindex++) { // hitung total harga paket untuk treatment ini
                const history = userPackage.history[zindex];
                userPackageQuantity += history.quantity;
              }
              total += Math.floor(userPackage.total / userPackageQuantity);
            }
          }
        }
        return total
      } 
      return 0;
    },
    totalProduct() {
      if (this.products.length) {
        let total = 0
        this.products.forEach(product => {
          total += product.price * product.quantity
        })
        return total
      } else {
        return 0
      }
    },
    totalTreatment() {
      let total = 0
      this.form.treatments.forEach( t => {
        total += (t.treatment === null)? 0 : t.treatment.price
      })
      return total
    },
    discountPackage() {
      if (this.selectedPackageTreatment.length) {
        let discount = 0
        for (let index = 0; index < this.form.treatments.length; index++) { // looping treatment
          const treatmentInForm = this.form.treatments[index];
          for (let xindex = 0; xindex < this.packages.length; xindex++) { // looping paket
            const paket = this.packages[xindex];
            if (treatmentInForm.treatment.treatmentId == paket.treatment._id) {
              const userPackage = this.packages[xindex].userPackage;
              let userPackageQuantity = 0;
              for (let zindex = 0; zindex < userPackage.history.length; zindex++) { // hitung total harga paket untuk treatment ini
                const history = userPackage.history[zindex];
                userPackageQuantity += history.quantity;
              }
              discount += treatmentInForm.treatment.price - Math.floor(userPackage.total / userPackageQuantity);
            }
          }
        }
        return discount
      } 
      return 0;
    },
    subTotal() {
      return this.totalTreatment + this.totalProduct - this.totalPackage - this.discountPackage
    },
    discountLabel() {
      return this.form.discount * this.subTotal / 100
    },
    discountVoucher() {
      if (this.voucher) {
        if (this.voucher.type == 'fixed') {
          return this.voucher.amount
        } else {
          return (this.subTotal - this.discountLabel) * this.voucher.amount / 100;
        }
      } else {
        return 0
      }
    },
    total() {
      const total = this.subTotal - this.discountLabel - this.discountVoucher
      return total > 0 ? total : 0
    }
  },
  watch: {
    voucherCode(newVal) {
      if (newVal == '' || newVal === null) {
        this.isVoucherInValid = false
        this.voucher = null
      }
    }
  },
  mounted() {
    this.getDetail()
    this.fetchTreatment()
  },
  methods: {
    onSubmit() {
      // do your submit logic here
      let param = new FormData();
      let _ = this;
      param.append("user", this.form.user.userId);
      param.append("room", (this.form.room !== null)? this.form.room.roomId : null);
      param.append("location", this.form.location.locationId);
      param.append("date", moment(this.form.date).format('YYYY-MM-DD'));
      param.append("time", this.form.time);
      param.append("status", this.form.status);
      param.append("payment_status", this.form.paymentStatus); 
      param.append("payment_method", (this.form.paymentStatus == 'paid')? this.form.paymentMethod : null)
      param.append("discount", Number(this.form.discount));
      // this.form.treatments.forEach( t => {
      //   param.append('treatments', t.treatment.treatmentId)
      //   param.append('employees', (t.employee !== null)? t.employee.employeeId : null)
      // })

      this.payload.treatments.forEach(t => {
        param.append('treatments', t.treatmentId);
        param.append('employees', t.employeeId);
        if (_.checkout && t.userPackageTreatmentId)
          param.append('userPackagesTreatment',t.userPackageTreatmentId);
      })

      param.append("checkout", (this.checkout)? 1 : 0)
      // if (this.checkout) {
      //   this.packages.forEach( p => {
      //     param.append('userPackagesTreatment', p._id)
      //   })
      // }
      param.append("discountPackage", this.totalPackage)
      param.append("discountPackageBalancing", this.discountPackage)
      this.products.forEach( p => {
        param.append('product_id', p.id)
        param.append('product_name', p.name)
        param.append('product_quantity', p.quantity)
        param.append('product_price', p.price)
      })
      if (this.form.notes) {
        param.append("notes", this.form.notes);
      }
      if (this.voucher) {
        param.append("voucher", this.voucher._id)
      }

      this.$axios
        .put(`/appointment/${this.$route.params.id}`, param)
        .then(() => {
          if (this.checkout) {
            Swal.fire("Success", "Checked Out Successfully", "success");
          } else {
            Swal.fire("Success", "Appointment Updated Successfully", "success");
          }
          this.$router.push(`/appointment`);
        })
        .catch((e) => {
          if (e.response) {
            this.isError = true;
            this.errorMessage = e.response.data.message;
          }
        });
      
    },
    validateForm(checkout = false) {
      // validation
      this.isUserNotSelected = this.form.user === null ? true : false;
      this.islocationsNotSelected = this.form.location === null ? true : false;
      this.isRoomNotSelected = this.form.room === null ? true : false;
      this.isDateNotSelected = this.form.date === null ? true : false;
      this.isTimeNotSelected = this.form.time === null ? true : false;
      this.isStatusNotSelected = this.form.status === null ? true : false;
      this.isPaymentStatusNotSelected = this.form.paymentStatus === null ? true : false;
      this.isPaymentMethodNotSelected = (this.form.paymentStatus == "paid" && (this.form.paymentMethod === null || this.form.paymentMethod == 'null')) ? true : false;

      let valid = true;

      if (checkout) {
        
        if (this.isUserNotSelected || this.islocationsNotSelected || this.isRoomNotSelected || this.isDateNotSelected || this.isTimeNotSelected || this.isStatusNotSelected || this.isPaymentStatusNotSelected || this.isPaymentMethodNotSelected) {
          return false;
        }

        this.form.treatments.forEach( async (t) => {
          t.treatmentNotSelected = (t.treatment === null)? true : false;
          t.employeeNotSelected = (t.employee === null)? true : false;
        })

        this.form.treatments.forEach( async (t) => {
          if(t.treatmentNotSelected || t.employeeNotSelected) {
            valid = false
          }
        })

      } else {

        if (this.isUserNotSelected || this.islocationsNotSelected || this.isDateNotSelected || this.isTimeNotSelected || this.isStatusNotSelected || this.isPaymentStatusNotSelected) {
          return false;
        }

        this.form.treatments.forEach(  async (t) => {
          t.treatmentNotSelected = (t.treatment === null)? true : false;
        })

        this.form.treatments.forEach(  async (t) => {
          if(t.treatmentNotSelected) {
            valid = false
          }
        })
      }

      return valid;
    },
    async checkoutAppointment() {
      const isFormValid = await this.validateForm(true)
      if (!isFormValid){
        console.log(isFormValid)
        return false
      }

      if (this.form.paymentStatus == 'paid') {

        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, checkout!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.checkout = true
            this.onSubmit()
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.checkout = false
          }
        })

      } else {

        Swal.fire({
          title: "Appointment can't be processed",
          text: "Appointment is still unpaid!",
          icon: "error",
          confirmButtonText: "Close",
        })

      }
    },
    async updateAppointment() {
      const isFormValid = await this.validateForm()
      if (isFormValid) {
        this.onSubmit()
      }
    },
    getDetail() {
      this.$axios.get(`appointment/${this.$route.params.id}`)
        .then( async (response) => {
          const app = response.data.result.appointment
          const treatments = response.data.result.appointment.treatments
          const products = response.data.result.appointment.products

          this.form.user = app.user
          this.form.user.userId = app.user._id

          this.form.location = app.location
          this.form.location.locationId = app.location._id
          this.findRoom({locationId: app.location._id})

          if (app.room) {
            this.form.room = app.room
            this.form.room.roomId = app.room._id
          }

          this.form.paymentStatus = app.payment_status
          this.form.paymentMethod = app.payment_method
          this.form.status = app.status
          this.form.date = moment(app.date).format('YYYY-MM-DD')
          this.form.time = app.time
          this.form.discount = app.discount
          this.form.notes = app.notes

          this.form.treatments = []
          treatments.forEach( async (t) => {
            const treatment = t.treatment
            treatment.treatmentId = t.treatment._id
            let employee = null
            if (t.employee) {
              employee = t.employee            
              employee.employeeId = t.employee._id
            }

            this.form.treatments.push({
              treatment: treatment,
              treatmentNotSelected: false,
              employee: employee,
              employees: [],
              employeeNotSelected: false
            })
          })

          products.forEach(p => {
            this.products.push({
              name: p.name,
              quantity: p.quantity,
              price: p.price,
              priceStr: this.convertToRupiah(p.price, false),
              subTotalStr: this.convertToRupiah(p.price * p.quantity, false)
            })
          })

          const date = moment(app.date).format('YYYY-MM-DD')
          let param = new FormData()
          param.append('date', date)
          param.append('appointment', this.$route.params.id)
          treatments.forEach( async (t) => {
            param.append('treatments', t.treatment._id)
          })
          const timeSlots = await this.$axios.post(`location/time-slot/${app.location.locationId}`, param)
          this.timeSlot = []
          timeSlots.data.result.timeSlot.forEach( data => {
            if (!data.disabled) {
              this.timeSlot.push(data.time)
            }
          })
          this.findPackage()
        })
    },
    findUser(query) {
      this.isLoadingUser = true;
      this.$axios.get(`user`, {
          params: {
            search: query,
            sortBy: "name",
            sortType: 1,
          },
        }).then((response) => {
          let result = response.data.result.users;
          this.users = result.map( user => {
            return Object.assign(user, {
              fullname: user.firstname + ' ' + user.lastname
            })
          });
          this.isLoadingUser= false;
        });
    },
    fetchTreatment() {
      this.$axios
        .get(`treatment/list/active`, {
          params: {
            sortBy: "name",
            sortType: 1,
            limit: 500,
            availability: 'booking_system'
          },
        })
        .then((response) => {
          let result = response.data.result.treatment;
          this.treatments = result;
        });
    },
    findEmployee(id) {
      const index = id.split("_").pop()
      const treatment = this.form.treatments[index].treatment
      
      if (treatment === null) {
        alert('please select the treatment data first')
        return false
      }
      if (this.form.date === null) {
        alert('please select the appointment date first')
        return false
      }
      if (this.form.time === null) {
        alert('please select the appointment time first')
        return false
      }

      let param = new FormData()
      param.append('date', this.form.date)
      param.append('time', this.form.time)

      this.$axios.post(`employee/list/by-treatment/${treatment.treatmentId}`, param)
        .then( result => {
          this.form.treatments[index].employees = result.data.result.employee
        });
    },
    generatePackages(usedUserPackageTreatmentIds, packageId, treatmentId) {
      const availableUserPackageTreatments = this.availablePackages
        .filter(x => x.package._id == packageId)
        .filter(x => x.treatment._id == treatmentId)
        .filter(x => !usedUserPackageTreatmentIds.includes(x._id));

      if (availableUserPackageTreatments.length > 0)
        return availableUserPackageTreatments[0]._id;

      return null;
    },
    addTreatment() {
      this.packages = [];

      this.form.treatments.push({
        treatment: null,
        treatmentNotSelected: false,
        employee:null,
        employeeNotSelected: false,
        employees: []
      })
    },
    removeTreatment(index) {
      // if (this.form.treatments[index].treatment !== undefined && this.form.treatments[index].treatment !== null) {
      //   this.resetForm()
      // }
      this.form.treatments.splice(index, 1)
      this.findPackage()
    },
    findLocation() {
      let param = new FormData()
      this.form.treatments.forEach( item => {
        if (item.treatment !== null) {
          param.append('treatments', item.treatment.treatmentId)
        }
      })
      this.$axios
        .post(`location/list/by-treatment`, param)
        .then((response) => {
          let result = response.data.result.location;
          this.locations = result;
        });
    },
    resetLocation() {
      this.form.location = null
      this.locations = []
    },
    findRoom(selected) {
      this.resetForm('room')
      let param = new FormData()
      this.form.treatments.forEach( item => {
        if (item.treatment !== null) {
          param.append('treatments', item.treatment.treatmentId)
        }
      })
      this.$axios
        .post(`room/list/by-location/${selected.locationId}`, param)
        .then((response) => {
          let result = response.data.result.room;
          this.rooms = result
        });
      this.findPackage()
    },
    findTimeSlot(chosen) {
      this.resetForm('time')
      const date = moment(chosen).format('YYYY-MM-DD')
      let param = new FormData()
      param.append('date', date)
      this.form.treatments.forEach( t => {
        param.append('treatments', t.treatment.treatmentId)
      })
      this.$axios.post(`location/time-slot/${this.form.location.locationId}`, param)
      .then( response => {
        this.timeSlot = []
        response.data.result.timeSlot.forEach( data => {
          if (!data.disabled) {
            this.timeSlot.push(data.time)
          }
        })
      })
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    resetForm(section = null) {
      switch(section) {
        case 'location':
          this.form.location = null
          this.locations = []
          break;
        case 'room': 
          this.form.room = null
          this.rooms = []
          break;
        case 'date':
          this.form.date = null
          break;
        case 'time':
          this.form.time = null
          this.timeSlot = []
          break;
        case 'employee':
          // eslint-disable-next-line no-case-declarations
          let treatments = []
          for (let index = 0; index < this.form.treatments.length; index++) {
            const element = this.form.treatments[index];
            let treatment = {
              treatment: element.treatment,
              treatmentNotSelected: false,
              employee:null,
              employeeNotSelected: false,
              employees: []
            }
            treatments.push(treatment)
          }
          this.form.treatments = treatments
          break;
        default:
          // reset location
          this.form.location = null
          this.locations = []
          // reset room 
          this.form.room = null
          this.rooms = []
          // reset date
          this.form.date = null
          // reset time
          this.form.time = null
          this.timeSlot = []
          // reset employee
          // let list = []
          for (let index = 0; index < this.form.treatments.length; index++) {
            // const element = this.form.treatments[index];
            // let treatment = {
            //   treatment: element.treatment,
            //   treatmentNotSelected: false,
            //   employee:null,
            //   employeeNotSelected: false,
            //   employees: []
            // }
            // list.push(treatment)
            this.form.treatments[index].employee = null
            this.form.treatments[index].employees = []
          }
          //this.form.treatments = list
          break;
      }
    },
    findPackage() {
      let param = new FormData()
      param.append('location', this.form.location.locationId)
      param.append("date", moment().format('YYYY-MM-DD'));
      param.append("time", this.form.time);

      this.form.treatments.forEach( item => {
        if (item.treatment !== null) {
          param.append('treatments', item.treatment.treatmentId)
        }
      })
      this.$axios
        .post(`user-package/${this.form.user.userId}/check-package`, param)
        .then((response) => {
          let result = response.data.result.packages;
          this.availablePackages =  [
            ...new Map(result.map(item => [item._id, item])).values()
          ];
        });
    },
    packageOptions(treatmentId) {
      const availablePackages = this.availablePackages
        .filter(x => x.treatment._id == treatmentId);

      const packages = [];
      for (let i = 0; i < availablePackages.length; i++) {
        const elm = availablePackages[i].package;
        if (packages.map(x => x._id).indexOf(elm._id) == -1)
          packages.push({
            _id: elm._id,
            name: elm.name,
          });
      }

      return packages;
    },
    addProduct(product) {
      this.products.push({
        id: product.id,
        name: product.name,
        quantity: product.quantity,
        price: product.price,
        priceStr: this.convertToRupiah(product.price, false),
        subTotalStr: this.convertToRupiah(product.price * product.quantity, false),
        stock: product.stock
      })
      this.showPopUpAddProduct = false
    },
    deleteProduct(index) {// add product in cart to product woo commerece
      const product = this.products[index];
      this.$refs.popupProduct.addProductToWooCommerece(product);
      this.products.splice(index, 1);
    },
    redeemVoucher() {
      let param = new FormData()
      param.append("voucher", this.voucherCode);
      param.append("location", this.form.location.locationId)
      param.append("date", moment(this.form.date).format('YYYY-MM-DD'));

      this.$axios
        .post(`voucher/redeem`, param)
        .then((response) => {
          console.log(response)
          this.isVoucherInValid = false
          this.voucher = response.data.result.voucher
        })
        .catch((error) => {
          console.log(error)
          this.isVoucherInValid = true
          this.voucher = null
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- <json-viewer :value="packages"></json-viewer>
    <json-viewer :value="form.treatments"></json-viewer> -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert v-model="isError" variant="danger" class="mt-3" dismissible>{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="updateAppointment" ref="formEdit" id="formEdit">
              <div class="form-group">
                <label>User</label>
                <multiselect
                  :class="{ 'is-invalid': isUserNotSelected }"
                  v-model="form.user"
                  label="fullname"
                  track-by="userId"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="users"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoadingUser"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="false"
                  @search-change="findUser"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.firstname +' '+ option.lastname  }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="isUserNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group row" v-for="(item,i) in form.treatments" :key="i">
                <div class="col">
                  <label>Treatment</label>
                  <multiselect 
                    :id="`treatment_${i}`"
                    v-model="item.treatment"
                    label="name"
                    track-by="treatmentId"
                    placeholder="Type to search"
                    open-direction="bottom"
                    :maxHeight="500"
                    :options="treatments"
                    :multiple="false"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :hide-selected="false"
                    :allowEmpty="false"
                    :class="{ 'is-invalid': item.treatmentNotSelected }"
                    @select="findPackage"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.name }}</strong>
                    </template>
                  </multiselect>
                  <div v-if="item.treatmentNotSelected" class="invalid-feedback">
                    <span>This value is required.</span>
                  </div>
                </div>
                <div class="col">
                  <label>Employee</label>
                  <multiselect 
                    :id="`employee_${i}`"
                    v-model="item.employee"
                    label="name"
                    track-by="employeeId"
                    placeholder="Type to search"
                    open-direction="bottom"
                    :maxHeight="500"
                    :options="item.employees"
                    :multiple="false"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :hide-selected="false"
                    :allowEmpty="false"
                    @open="findEmployee"
                    :class="{ 'is-invalid': item.employeeNotSelected }"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.name }}</strong>
                    </template> 
                  </multiselect>
                  <div v-if="item.employeeNotSelected" class="invalid-feedback">
                    <span>This value is required.</span>
                  </div>
                </div>
                <div class="col" v-if="item.treatment">
                  <label>Package</label>
                  <multiselect
                    :deselect-label="null"
                    v-model="item.package"
                    label="name"
                    track-by="_id"
                    placeholder="Select One Package"
                    open-direction="bottom"
                    :maxHeight="500"
                    :options="packageOptions(item.treatment.treatmentId)"
                    :multiple="false"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :hide-selected="false"
                    :allowEmpty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.name }}</strong>
                    </template>
                    <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                  </multiselect>
                </div>
                <b-button variant="danger" class="ml-2 mr-3 align-self-end mb-1" style="height: fit-content;" @click="removeTreatment(i)" v-show="i || ( !i && form.treatments.length > 1)"><span class="fa fa-trash"></span></b-button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <b-button @click="addTreatment()" variant="info"><span class="fa fa-plus"></span> Add Treatment</b-button>
              </div>

              <div class="form-group">
                <label>Location</label>
                <multiselect
                  :class="{ 'is-invalid': islocationsNotSelected }"
                  :deselect-label="null"
                  v-model="form.location"
                  label="name"
                  track-by="locationId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :maxHeight="500"
                  :options="locations"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                  @open="findLocation"
                  @select="findRoom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="islocationsNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Room</label>
                <multiselect
                  :class="{ 'is-invalid': isRoomNotSelected }"
                  v-model="form.room"
                  label="name"
                  track-by="roomId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :options="rooms"
                  :maxHeight="500"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="isRoomNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Date</label>
                <br />
                <date-picker
                  v-model="form.date"
                  format="DD MMMM YYYY"
                  name="date"
                  :first-day-of-week="1"
                  lang="en"
                  placeholder="Select One"
                  :class="{ 'is-invalid': isDateNotSelected }"
                  @pick="findTimeSlot"
                  value-type="YYYY-MM-DD"
                  :disabled-date="notBeforeToday"
                ></date-picker>
                <div v-if="isDateNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              
              <div class="form-group">
                <label>Select Time</label>
                <multiselect :class="{ 'is-invalid': isTimeNotSelected }" v-model="form.time" @select="findPackage" deselect-label="Can't remove this value" placeholder="Select one" :options="timeSlot" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isTimeNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group row">
                <label class="ml-3">Products</label>
                <template v-if="products.length">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-lg-6">
                        <label>Name</label>
                      </div>
                      <div class="col-lg-1">
                        <label>Quantity</label>
                      </div>
                      <div class="col-lg-2">
                        <label>Price</label>
                      </div>
                      <div class="col-lg-2">
                        <label>Subtotal</label>
                      </div>
                    </div>
                    <div class="row mb-2" v-for="(product, index) in products" :key="index">
                      <div class="col-lg-6">
                        <input
                          v-model="product.name"
                          class="form-control"
                          type="text"
                          readonly
                        />
                      </div>
                      <div class="col-lg-1">
                        <input
                          v-model="product.quantity"
                          class="form-control"
                          type="number"
                          readonly
                        />
                      </div>
                      <div class="col-lg-2">
                        <b-input-group prepend="Rp.">
                          <input
                            v-model="product.priceStr"
                            class="form-control"
                            type="text"
                            readonly
                          />
                        </b-input-group>
                      </div>
                      <div class="col-lg-2">
                        <b-input-group prepend="Rp.">
                          <input
                            v-model="product.subTotalStr"
                            class="form-control"
                            type="text"
                            readonly
                          />
                        </b-input-group>
                      </div>
                      <div class="col-lg-1">
                        <button type="button" @click="deleteProduct(index)" class="btn btn-sm btn-danger">Remove</button>
                      </div>
                    </div>
                    <button type="button" v-if="this.form.location" @click="showPopUpAddProduct = true" class="btn btn-success btn-block mt-3">Add Product</button>
                  </div>
                </template>
                <template v-else>
                  <div class="col-lg-12">
                    <button type="button" v-if="this.form.location" @click="showPopUpAddProduct = true" class="btn btn-secondary btn-block">no products have been added, klick to add</button>
                  </div>
                </template>
              </div>

              <div class="form-group">
                <label>Payment Status</label> 
                <multiselect :class="{ 'is-invalid': isPaymentStatusNotSelected }" v-model="form.paymentStatus" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsPayment" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isPaymentStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group" v-if="form.paymentStatus == 'paid'">
                <label>Payment Method</label> 
                <multiselect :class="{ 'is-invalid': isPaymentMethodNotSelected }" :disabled="disabledPaymentMethod" v-model="form.paymentMethod" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsPaymentMethod" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isPaymentMethodNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Booking Status</label> 
                <multiselect :class="{ 'is-invalid': isStatusNotSelected }" v-model="form.status" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsStatus" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Booking Notes</label> 
                <textarea
                  v-model="form.notes"
                  class="form-control"
                  placeholder="input your booking notes here"
                  name="notes"
                  rows="3"
                ></textarea>
              </div>

              <div class="border-bottom mb-10 mt-10"></div>

              <div class="row">
                <div class="col-lg-6">
                  <!-- <div class="form-group" v-if="packageOnAvailablePackages.length">
                    <label>Package</label>
                    <multiselect
                      :deselect-label="null"
                      v-model="selectedPackageId"
                      label="name"
                      track-by="_id"
                      placeholder="Select One Package"
                      open-direction="bottom"
                      :maxHeight="500"
                      :options="packageOnAvailablePackages"
                      :multiple="false"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :hide-selected="false"
                      :allowEmpty="false"
                      @select="(res) => generatePackages(res)"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <strong>{{ option.name }}</strong>
                      </template>
                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                  </div> -->
                  <!-- <template v-if="$store.getters.checkPermission('SET_DISCOUNT_APPOINTMENT')">
                    <div class="form-group">
                      <label>Discount</label>
                      <b-input-group append="%">
                        <input
                          v-model="form.discount"
                          type="number"
                          min="0"
                          step="0.5"
                          class="form-control"
                          name="discount"
                          placeholder="input discount"
                        />
                      </b-input-group>
                    </div>
                  </template> -->
                  <div class="form-group">
                    <label>Voucher</label>
                    <b-input-group :class="{ 'is-invalid': isVoucherInValid }">
                      <input
                        v-model="voucherCode"
                        type="text"
                        class="form-control"
                        name="voucher"
                        placeholder="input voucher code here"
                        :class="{ 'is-invalid': isVoucherInValid }"
                        @keyup.enter="redeemVoucher"
                      />
                    
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="redeemVoucher">Redeem</b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="isVoucherInValid" class="invalid-feedback">
                      <span>Voucher Not Valid</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="border-bottom mb-2">                    
                    <p class="font-14 font-weight-bold d-block mb-0">
                      Total Treatment
                      <span class="float-right">Rp. {{ totalTreatment | convertToRupiah }}</span>
                    </p>
                  </div>
                  <div class="border-bottom mb-2">                    
                    <p class="font-14 font-weight-bold d-block mb-0">
                      Total Product
                      <span class="float-right">Rp. {{ totalProduct | convertToRupiah }}</span>
                    </p>
                  </div>
                  <div class="border-bottom mb-2">                    
                    <p class="font-14 font-weight-bold d-block mb-0">
                      Total Package
                      <span class="float-right">Rp. {{ totalPackage | convertToRupiah }}</span>
                    </p>
                  </div>
                  <template v-if="$store.getters.checkPermission('SET_DISCOUNT_APPOINTMENT')">
                    <div class="border-bottom mb-2">                    
                      <p class="font-14 font-weight-bold d-block mb-0">
                        Total Discount
                        <span class="float-right">Rp. {{ discountPackage | convertToRupiah }}</span>
                      </p>
                    </div>
                  </template>
                  <div class="border-bottom mb-2">                    
                    <p class="font-14 font-weight-bold d-block mb-0">
                      Total Voucher
                      <span class="float-right">Rp. {{ discountVoucher | convertToRupiah }}</span>
                    </p>
                  </div>
                  <div class="border-bottom mb-5">                    
                    <p class="font-14 font-weight-bold d-block mb-0">
                      Total Payment
                      <span class="float-right">Rp. {{ total | convertToRupiah }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div>
                  <button type="submit" ref="saveChange" class="btn btn-primary">Save Change</button>
                  <button type="button" v-if="form.status == 'completed' && form.paymentStatus == 'paid' && form.paymentMethod != 'null'" @click.prevent="checkoutAppointment" class="btn btn-danger float-right">Checkout</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
    <PopUpAddProduct ref="popupProduct" v-if="form.location" :location="form.location.locationId" :show="showPopUpAddProduct" @close="showPopUpAddProduct = false" @add="addProduct"></PopUpAddProduct>

    <!-- <json-viewer :value="payload" :expanded="true" :expand-depth="5"></json-viewer> -->
  </Layout>
</template>

<style scoped>
input:read-only {
  background-color: #dbd3d3;
}
</style>